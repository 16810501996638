/* eslint-disable-next-line */
import { user } from '@/v3/features/user/data/services/user';
import { NgClass, NgIf, NgStyle, ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { country } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';
import { getMerchantIdVerification } from '@presentation/kyc/atoms/utils/get-verification-object';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import {
  ProductAvailability,
  ProductBasicInfo,
  Variant,
} from 'src/app/core/domain/products/product-detail.model';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { IsFeatureEnabledUseCase } from 'src/app/core/usecases/market-availability/is-feature-enabled.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { PRE_ORDER_USER_FEATURE } from 'src/app/presentation/shared/constants';
import {
  MARKETPLACE_USER_FEATURE,
  MARKETPLACE_V3_2_USER_FEATURE,
  PREORDER_V3,
  WEB_MARKETPLACE_V3,
  WEB_MARKETPLACE_V3_2,
  WEB_MARKETPLACE_V3_3,
  WEB_MARKETPLACE_V3_4,
} from 'src/app/presentation/shared/constants/feature-flags';
import { ProductActionMarketplaceComponent } from './product-action-marketplace/product-action-marketplace.component';
/* eslint-disable */
import { mixPanelEvent } from '@/v3/features/activities/data/mixpanel/index';
import { lockingRequestTrackEvents } from '@/v3/features/product-lock-request/data/constants';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  lockedTestableProductsTrackEvents,
  MerchantTestableProductRelationModel,
} from '@features/locked-testable-products/data';
import { LockedTestableProductsUseCaseResolver } from '@features/locked-testable-products/domain';
import { OnHoverTooltipComponent } from 'app/presentation/shared/components/on-hover-tooltip/on-hover-tooltip.component';
import { ProgressBarComponent } from 'app/presentation/shared/components/progress-bar/progress-bar.component';
import { ProductLockExtensionPopupComponent } from './product-lock-extension-popup/product-lock-extension-popup.component';
/* eslint-enable */

@Component({
  selector: 'app-product-actions',
  templateUrl: './product-actions.component.html',
  styleUrls: ['./product-actions.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgStyle,
    TranslateModule,
    MatRadioModule,
    FormsModule,
    ProductActionMarketplaceComponent,
    OnHoverTooltipComponent,
    ProgressBarComponent,
  ],
})
export class ProductActionsComponent extends LocalizedComponent implements OnInit, OnChanges {
  @Input() basicInfo: ProductBasicInfo;

  @Input() currentProduct: Variant;

  @Input() productAvailability: ProductAvailability;

  @Input() isMarketplaceEnabled: boolean;

  @Input() productIsCataloged: boolean;

  @Input() bulkPreOrderButtonIsVisible: boolean;

  @Input() productIsOrderable: boolean;

  @Input() userHasStockAvailability: boolean;

  @Input() isDiscountOnQuantityEnabled: boolean;

  @Input() isBundle: boolean;

  @Output() addToCart: EventEmitter<void> = new EventEmitter();

  @Output() orderNow: EventEmitter<number> = new EventEmitter();

  @Output() openBulkRequestDialog: EventEmitter<void> = new EventEmitter();

  @Output() toggleIsCataloged: EventEmitter<void> = new EventEmitter();

  productAvailabilityTranslationKey: string;

  availabilityTextColor: string;

  productAvailabilityEnum = ProductAvailability;

  preOrderV3Flag = false;

  currentCountry: string;

  isMarketClosed = false;

  isDuplicateAccount = false;

  shouldShowMarketplaceV3 = false;

  shouldShowMarketplaceV3_2 = false;

  orderActionSelected: 'order-now' | 'marketplace' | 'preorder' = 'order-now';

  isMarketplaceRequestTypeUpdate = false;

  shouldHideActionOption = false;

  public allowedToLockProduct = false;

  public showAllowedToTestProductHint = false;

  public currentStockSellingProgress: number;

  public marketplaceV3_3Enabled = false;

  public shouldShowTestingProgress = false;

  public remainingPcsToEnableLocking: number;

  public shouldShowMarketplaceV3_4 = false;

  public merchantTestableProductRelationData: MerchantTestableProductRelationModel;

  public shouldDisableTestingOrders = false;

  public stockIsExpired = false;

  constructor(
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _isFeatureEnabledUseCase: IsFeatureEnabledUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _route: ActivatedRoute,
    private scroller: ViewportScroller,
    private _matDialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkIfStockIsExpired();
    this.checkMerchantIdVerification();
    this.determineProductOrderStatus();

    this.isMarketplaceV3_4Enabled();
    if (!this.isMarketplaceEnabled && this.isBundle) {
      this.isBundle = false;
    }
    this._isFeatureEnabledUseCase.execute('orders').subscribe((res) => {
      this.isMarketClosed = !res;
    });
    this.enableMarketplaceV3();

    this.enableMarketplaceV3_2();

    this.checkIfAllowedToTestLockedProduct();

    this.checkIfMarketplaceV3_3Enabled();
  }

  public determineProductOrderStatus(): void {
    this.shouldHideActionOption = !this.productIsOrderable || this.isMarketClosed;
    if (this.shouldHideActionOption) {
      this.orderActionSelected = 'preorder';
    }
  }

  ngOnChanges(): void {
    this.mapProductAvailability();
    this.calculateStockSellingProgress();
  }

  protected checkMerchantIdVerification(): void {
    getMerchantIdVerification(
      this._getFeatureFlagUseCase,
      this._checkUserFeatureExistsUseCase,
    ).then((response) => {
      this.isDuplicateAccount = response.isDuplicate;
      if (!this.isDuplicateAccount) {
        this.checkPreOrderFeature();
      }
    });
  }

  checkPreOrderFeature(): void {
    this._getFeatureFlagUseCase.execute(PREORDER_V3).subscribe((flag) => {
      const selectedCountry = country.code;
      this.preOrderV3Flag =
        flag &&
        this._checkUserFeatureExistsUseCase.execute(
          PRE_ORDER_USER_FEATURE + selectedCountry.toLowerCase(),
        );
    });
  }

  public enableMarketplaceV3(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3).subscribe({
      next: (attribute) => {
        this.shouldShowMarketplaceV3 =
          featureAttributeAssign(attribute, user.id, country.code) &&
          user.features.includes(MARKETPLACE_USER_FEATURE);
        if (this.shouldShowMarketplaceV3) {
          this._checkMarketplaceRequestType();
          this._checkActivationParams();
        }
      },
    });
  }

  private enableMarketplaceV3_2(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3_2).subscribe({
      next: (attribute) => {
        this.shouldShowMarketplaceV3_2 =
          featureAttributeAssign(attribute, user.id, country.code) &&
          user.features.includes(MARKETPLACE_V3_2_USER_FEATURE);
      },
    });
  }

  private checkIfAllowedToTestLockedProduct(): void {
    LockedTestableProductsUseCaseResolver.merchantTestableProductRelation(this.currentProduct._id)
      .then((res) => {
        this.merchantTestableProductRelationData = res;
        this.allowedToLockProduct = this.shouldShowMarketplaceV3_2
          ? !(
              res.lockingRequestAvailable &&
              this.productAvailability !== this.productAvailabilityEnum.available_for_preorder
            )
          : this.productAvailability === this.productAvailabilityEnum.available_for_preorder;

        this.showAllowedToTestProductHint =
          this.shouldShowMarketplaceV3_2 &&
          (res.orderTestingAvailable || res.orderTestingStarted) &&
          !res.lockingRequestAvailable;

        this.shouldShowTestingProgress = res.orderTestingStarted && !res.lockingRequestAvailable;

        this.checkIfShouldDisableTestingOrders();
      })
      .catch(() => {
        // fallback condition for the allowedToLockProduct
        this.allowedToLockProduct =
          this.productAvailability === this.productAvailabilityEnum.available_for_preorder;
      });
  }

  private _checkMarketplaceRequestType(): void {
    if (this.currentProduct.availabilityInfo.stockInfo) {
      this.isMarketplaceRequestTypeUpdate = true;
    }
  }

  private isMarketplaceV3_4Enabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3_4).subscribe({
      next: (attribute) => {
        this.shouldShowMarketplaceV3_4 = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  private _checkActivationParams(): void {
    const { queryParams } = this._route.snapshot;
    // eslint-disable-next-line
    const { stockIsExpired, currentProduct, shouldShowMarketplaceV3_4 } = this;
    const { lockExtension, showMarketplace } = queryParams;
    const totalLockedStock = currentProduct.availabilityInfo?.stockInfo?.totalLockedStock;

    const shouldShowExtensionDialog =
      (stockIsExpired || totalLockedStock) && shouldShowMarketplaceV3_4 && lockExtension;
    const shouldScrollToLocking = showMarketplace || (lockExtension && !totalLockedStock);

    if (shouldShowExtensionDialog) {
      this._openExtensionDialog();
    } else if (shouldScrollToLocking) {
      this._scrollToLocking();
    }
  }

  private _openExtensionDialog(): void {
    this._matDialog.open(ProductLockExtensionPopupComponent, {
      width: '770px',
      direction: this.isLTR ? 'ltr' : 'rtl',
      autoFocus: false,
      data: {
        sku: this.currentProduct.prodID,
        productName: this.currentProduct.productName,
      },
    });
  }

  private _scrollToLocking(): void {
    this.scroller.scrollToAnchor('orderingActions');
    const pos = this.scroller.getScrollPosition();
    this.scroller.scrollToPosition([0, pos[1] + 400]);
    this.orderActionSelected = 'marketplace';
  }

  mapProductAvailability(): void {
    switch (this.productAvailability) {
      case ProductAvailability.available:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE';
        this.availabilityTextColor = '#3dbb54';
        break;
      case ProductAvailability.available_for_preorder:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_FOR_PRE_ORDER';
        this.availabilityTextColor = '#3dbb54';
        break;
      case ProductAvailability.not_available:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_NOT_AVAILABLE';
        this.availabilityTextColor = '#ff4966';
        break;
      case ProductAvailability.available_with_high_qty:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_WITH_HIGH_QUANTITY';
        this.availabilityTextColor = '#3dbb54';
        break;
      case ProductAvailability.available_with_low_qty:
        this.productAvailabilityTranslationKey =
          'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_WITH_LOW_QUANTITY';
        this.availabilityTextColor = '#ffae0c';
        break;
      default:
        this.productAvailabilityTranslationKey = '';
        this.availabilityTextColor = '#3dbb54';
        break;
    }
  }

  onAddToCart(): void {
    if (this.marketplaceV3_3Enabled && this.currentProduct.orderTestingConfig) {
      mixPanelEvent(lockedTestableProductsTrackEvents.ADD_TO_CART_INSIDE);
    }

    this.addToCart.emit();
  }

  onPreOrderRequestClick(): void {
    this.openBulkRequestDialog.emit();
  }

  onOrderNow(): void {
    if (this.marketplaceV3_3Enabled && this.currentProduct.orderTestingConfig) {
      mixPanelEvent(lockedTestableProductsTrackEvents.ORDER_NOW);
    }

    this.orderNow.emit();
  }

  onToggleIsCataloged(): void {
    this.toggleIsCataloged.emit();
  }

  trackMarketplace(): void {
    if (this.marketplaceV3_3Enabled && this.currentProduct.orderTestingConfig) {
      mixPanelEvent(lockedTestableProductsTrackEvents.LOCK_QUANTITY);
    }

    mixPanelEvent(lockingRequestTrackEvents.EXPLORE_LOCK_QUANTITIES);
  }

  private calculateStockSellingProgress(): void {
    if (
      this.currentProduct?.availabilityInfo?.stockInfo &&
      this.currentProduct.orderTestingConfig
    ) {
      const { totalLockedStock, remainingStock } = this.currentProduct.availabilityInfo.stockInfo;

      const minRequiredQuantityForEnablingLocking = Math.round(
        totalLockedStock * this.currentProduct.orderTestingConfig.succeededTestingLockRate,
      );

      const pcsSold = totalLockedStock - remainingStock;

      this.remainingPcsToEnableLocking = minRequiredQuantityForEnablingLocking - pcsSold;

      this.currentStockSellingProgress =
        ((minRequiredQuantityForEnablingLocking - this.remainingPcsToEnableLocking) /
          minRequiredQuantityForEnablingLocking) *
        100;
    }
  }

  private checkIfMarketplaceV3_3Enabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3_3).subscribe({
      next: (attribute) => {
        this.marketplaceV3_3Enabled = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  private checkIfStockIsExpired(): void {
    if (this.currentProduct.availabilityInfo?.stockInfo) {
      const { expiresAt, remainingStock } = this.currentProduct.availabilityInfo.stockInfo;

      if (expiresAt) {
        const currentDate = new Date().getTime();
        const expiryDate = new Date(expiresAt).getTime();

        this.stockIsExpired = currentDate > expiryDate;
      }

      if (remainingStock === 0) {
        this.stockIsExpired = true;
      }
    }
  }

  private checkIfShouldDisableTestingOrders(): void {
    this.shouldDisableTestingOrders =
      this.marketplaceV3_3Enabled &&
      this.merchantTestableProductRelationData?.orderTestingStarted &&
      this.stockIsExpired;

    if (this.shouldDisableTestingOrders) {
      this.orderActionSelected = 'marketplace';
    }
  }
}
