<div id="orderingActions" class="header-container product-actions-container">
  <div>
    <p *ngIf="!shouldShowMarketplaceV3" class="content-secondary-color no-margin body2--medium">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_READY_TO_SELL' | translate }}
    </p>
    <div class="full-height" *ngIf="!isDuplicateAccount; else isDuplicate">
      <section class="product-actions">
        <div class="product-availability-wrapper product-ordering-actions" *ngIf="!preOrderV3Flag">
          <p
            *ngIf="
              !userHasStockAvailability ||
              productAvailability === productAvailabilityEnum.available_for_preorder
            "
            class="product-availability"
          >
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PRODUCT_STATUS' | translate }} :
            <span class="product-availability-text" [ngStyle]="{ color: availabilityTextColor }">{{
              productAvailabilityTranslationKey | translate
            }}</span>
          </p>
          <button
            *ngIf="bulkPreOrderButtonIsVisible"
            class="action-button bulk-preorder-button clickable body2--bold"
            (click)="onPreOrderRequestClick()"
          >
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.BULK_PRE_ORDER' | translate }}
          </button>
        </div>
        <section id="ordering-actions" class="product-ordering-actions order-options">
          <mat-radio-group [(ngModel)]="orderActionSelected" aria-label="Select an option">
            <mat-radio-button
              [disabled]="
                shouldHideActionOption ||
                productAvailability === productAvailabilityEnum.not_available ||
                shouldDisableTestingOrders
              "
              value="order-now"
            >
              <p
                [ngClass]="{ 'disable-text': shouldHideActionOption }"
                class="body2--bold content-main ta-start"
              >
                {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.ORDER_TITLE') }}
              </p>
              <p class="caption1--regular">
                {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.ORDER_DESC') }}
              </p>
            </mat-radio-button>
            <ng-container *ngIf="orderActionSelected === 'order-now'">
              <div class="button-container">
                <button
                  class="order-action-button cart-button body2--bold"
                  (click)="onAddToCart()"
                  [disabled]="
                    productAvailability === productAvailabilityEnum.not_available ||
                    !productIsOrderable
                  "
                >
                  <img class="add-to-cart-icon" src="assets/img/cart-brand-color.svg" />
                  {{ 'PRODUCTS_PAGE.ADD_TO_CART' | translate }}
                </button>
                <button
                  class="order-action-button order-now-button clickable body2--bold"
                  (click)="onOrderNow()"
                  [disabled]="
                    productAvailability === productAvailabilityEnum.not_available ||
                    !productIsOrderable ||
                    isMarketClosed
                  "
                  data-test-id="order-now-btn"
                >
                  {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ORDER_NOW' | translate }}
                </button>
              </div>
            </ng-container>
            <mat-radio-button
              *ngIf="shouldShowMarketplaceV3"
              [disabled]="allowedToLockProduct"
              (click)="trackMarketplace()"
              value="marketplace"
            >
              <div class="lock-quantity">
                <p
                  [ngClass]="{
                    'disable-text':
                      productAvailability === productAvailabilityEnum.available_for_preorder
                  }"
                  class="body2--bold content-main ta-start"
                >
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_TITLE') }}

                  <span class="new-status-tag caption2--medium">
                    {{ trans('CATALOG_PAGE.NEW_TAB') }}
                  </span>
                </p>

                <ng-container *ngIf="showAllowedToTestProductHint">
                  <app-on-hover-tooltip
                    [targetTemplate]="ineligibleInfoTemplate"
                    [heading]="
                      'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.INELIGIBLE_TOOLTIP_TITLE'
                        | translate
                    "
                    [content]="
                      'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.INELIGIBLE_TOOLTIP_SUBTITLE'
                        | translate
                          : {
                              lockSuccessPercentage:
                                currentProduct.orderTestingConfig?.succeededTestingLockRate * 100
                            }
                    "
                    [tooltipStyles]="{ backgroundColor: '#13161F' }"
                    [arrowDirection]="'bottom'"
                    [fullWidth]="true"
                  ></app-on-hover-tooltip>

                  <ng-template #ineligibleInfoTemplate>
                    <div class="lock-quantity__ineligible">
                      <img
                        class="lock-quantity__ineligible_icon"
                        src="assets/img/ineligible-info.svg"
                        alt="info"
                      />
                      <span class="caption1--medium">{{
                        trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.INELIGIBLE_TITLE')
                      }}</span>
                    </div>
                  </ng-template>
                </ng-container>
              </div>

              <div class="tags">
                <p class="gray-bg caption1--regular">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_DAYS_TAG') }}
                </p>
                <p class="gray-bg caption1--regular">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_HOURS_TAG') }}
                </p>
              </div>
              <div
                *ngIf="marketplaceV3_3Enabled && shouldShowTestingProgress"
                class="testing-progress-container"
              >
                <div class="testing-progress-container_progress-bar">
                  <app-progress-bar [percentage]="currentStockSellingProgress"></app-progress-bar>
                </div>
                <p class="testing-progress-container_info caption3--regular">
                  <span class="caption1--medium">
                    {{ remainingPcsToEnableLocking }}
                  </span>
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PIECES_TO_SELL') }}
                </p>
              </div>
            </mat-radio-button>
            <ng-container *ngIf="orderActionSelected === 'marketplace'">
              <app-product-action-marketplace
                [isMarketplaceRequestTypeUpdate]="isMarketplaceRequestTypeUpdate"
                [basicInfo]="basicInfo"
              ></app-product-action-marketplace>
            </ng-container>
            <mat-radio-button value="preorder" *ngIf="preOrderV3Flag && !isBundle">
              <p class="body2--bold content-main ta-start">
                <img class="icon-style crown-img" src="assets/img/crown.svg" />
                {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_TITLE') }}
              </p>
              <div class="tags">
                <p class="crown-bg caption1--regular">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_TAG_1') }}
                </p>
                <p class="crown-bg caption1--regular">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_TAG_2') }}
                </p>
                <p class="crown-bg caption1--regular">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_TAG_3') }}
                </p>
              </div>
            </mat-radio-button>
            <ng-container *ngIf="orderActionSelected === 'preorder'">
              <div class="action-container">
                <div class="tags">
                  <p class="crown-bg body2--bold">
                    <img src="assets/img/crown.svg" />
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_SUB_TITLE') }}
                  </p>
                </div>
                <p class="body2--bold content-main preorder-title">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_MAIN') }}
                </p>
                <p class="caption2--regular ta-start">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_SUB') }}
                </p>
                <button (click)="onPreOrderRequestClick()" class="preorder-button body2--regular">
                  {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.PREORDER_MAIN_BUTTON') }}
                </button>
              </div>
            </ng-container>
          </mat-radio-group>
        </section>

        <section class="favorite-and-share-section">
          <button
            class="add-to-catalog-button clickable body2--bold"
            (click)="onToggleIsCataloged()"
          >
            <img class="icon-style" *ngIf="!productIsCataloged" src="assets/img/heart.svg" />
            <img class="icon-style" *ngIf="productIsCataloged" src="assets/img/heart-active.svg" />
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ADD_TO_CATALOG' | translate }}
          </button>
        </section>
        <section
          class="preorder-v3-section"
          *ngIf="preOrderV3Flag && !isBundle && !shouldShowMarketplaceV3"
        >
          <img src="assets/img/locked.svg" alt="lock" />
          <div>
            <p class="body1--bold content-main">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.TITLE' | translate }}
            </p>
            <p class="body2--regular content-medium">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.DESCRIPTION' | translate }}
            </p>
          </div>
          <button (click)="onPreOrderRequestClick()" class="preorder-v3-section__CTA">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.RESERVE' | translate }}
          </button>
        </section>
      </section>
    </div>
  </div>
</div>
<ng-template #isDuplicate>
  <div class="duplicate-account">
    <img src="assets/img/product-details-icons/lock.svg" alt="" />
    {{ trans('wallet.duplicateAccountError') }}
  </div>
</ng-template>
